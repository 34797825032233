lightVariantBlock = @block {
	background-color: #f9;
}

.FileCards {
	display: flex;
	flex-wrap: wrap;
	flex-shrink: 0;
	// Ben Eliason made me do this
	&:not(:empty) {
		margin-left: -5px;
		margin-right: -5px;
	}

	// .FileCards .FileCard
	.FileCard {
		margin: 5px;
	}

	// .FileCards--light .FileCard
	&--light .FileCard {
		fab-background-color: white;
		// @startCleanup encore
		border: 1px solid fab-color(gray4);
		// @endCleanup encore
		+encore() {
			border: 1px solid var(--gray3);
		}
	}
}

.FileCard {
	display: flex;
	flex-direction: column;
	position: relative;
	border: none;
	// @startCleanup encore
	border-radius: 2px;
	fab-background-color: gray2;
	// @endCleanup encore
	+encore() {
		border-radius: 16px;
		background-color: var(--gray2);
		height: 128px;
		width: var(--width-7);
		padding: 16px;
	}
	box-sizing: border-box;
	fab-text: teenie;
	// @startCleanup encore
	height: 112px;
	padding: 9px 12px 12px;
	width: 176px;
	// @endCleanup encore

	// .FileCard--light
	&--light {
		// @startCleanup encore
		fab-background-color: gray1;
		// @endCleanup encore
		+encore() {
			background-color: var(--gray1);

			.FileCard__close {
				&:before,
				&:after {
					background-color: var(--gray1);
				}
			}
		}
	}


	&--display {
		box-sizing: border-box;
		// @startCleanup encore
		border: 1px solid fab-color(gray4);
		box-shadow: none;
		fab-background-color: gray1;
		// @endCleanup encore
		+encore() {
			border: 1px solid (--gray3);
			box-shadow: 1px 1px 0px 2px rgba(56, 49, 47, 0.03);
			background-color: var(--gray1);
		}

		//override focus border for a11y
		&:focus {
			box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05), 0 0 0 2px fab-color(theme-lightest35);	
			outline: 0;	
		}

		// .FileCard--display .FileCard__detail
		.FileCard__detail {
			max-width: 100%;
		}
		// .FileCard--display .FileCard__title
		.FileCard__title {
			// @startCleanup encore
			fab-color: info;
			fab-font-weight: semibold;
			font-size: 14px;
			// @endCleanup encore
			+encore() {
				color: var(--link);
				display: inline-block;
				font-weight: var(--font-weight-medium);
				line-height: var(--line-height-medium);
				font-size: var(--font-size-medium);
				max-height: var(--height--biggie);
				overflow: hidden;
				max-width: 100%;
				min-height: var(--height--medium);
				word-break: break-word;
			}

			// @startCleanup encore
			&:hover{
				cursor: pointer;
				text-decoration: underline;
			}
			// @endCleanup encore
		}
		// .FileCard--display:hover
		&:hover:not(.FileCard--disabled) {
			cursor: pointer;
			border: 1px solid fab-color(gray4);
			// @startCleanup encore
			box-shadow: none;
			fab-background-color: gray2;
			// @endCleanup encore
			+encore() {
				background-color: var(--gray1);
				box-shadow: 1px 1px 0px 2px rgba(56, 49, 47, 0.03);
				border-color: var(--theme-base-default);
				transition: border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

				// .FileCard--display:hover .FileCard__title
				.FileCard__title {
					text-decoration: underline;
					transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, fill 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
					color: var(--theme-base-default);
				}
			}
		}
	}
	// .FileCard--light
	&.FileCard--light {
		// @startCleanup encore
		{lightVariantBlock};
		// @endCleanup encore
		+encore() {
			background-color: var(--gray1);

			.FileCard__close {
				&:before,
				&:after {
					background-color: var(--gray1);
				}
			}
		}
	}

	// .FileCard--disabled
	&--disabled {
		.FileCard__title,
		.FileCard__info {
			// @startCleanup encore
			color: #AF;
			// @endCleanup encore
			+encore() {
				color: inherit;
			}

			&:hover {
				cursor: default;
				text-decoration: none;
			}
		}

		.FileCard__btn,
		.FileCard__image {
			opacity: 0.5;
		}
		+encore() {
			.FileCard__info {
				opacity: 0.5;
			}

			&.FileCard--display {
				.FileCard__title {
					color: var(--link);
				}
			}
		}
	}

	// .FileCard__btn
	&__btn {
		fab-background-color: gray2;
		// @startCleanup encore
		border-radius: 2px;
		font-size: 12px;
		font-weight: 600;
		line-height: 16px;
		padding: 2px 6px;
		// @endCleanup encore
		margin: 0;
		overflow: ellipsis;
		+encore() {
			border-radius: 4px;
			font-size: var(--font-size-teenie);
			font-weight: var(--font-weight-medium);
			line-height: var(--line-height-teenie);
			padding: 4px 8px;
		}

		//.FileCard__btn--sign
		&--sign {
			// @startCleanup encore
			fab-background-color: action;
			color: fab-color(gray10);
			// @endCleanup encore
			+encore() {
				background-color: var(--warningDark);
				color: var(--white);
			}
		}
		
		//.FileCard__btn--signed
		&--signed {
			// @startCleanup encore
			fab-background-color: success;
			color: fab-color(white);
			// @endCleanup encore
			+encore() {
				background-color: var(--successDark);
				color: var(--white);
			}
		}

		//.FileCard__btn--optional
		&--optional {
			// @startCleanup encore
			fab-background-color: gray7;
			color: fab-color(white);
			// @endCleanup encore
			+encore() {
				background-color: var(--gray6);
				color: var(--white);
			}
		}
	}
	// .FileCard__close
	&__close {
		align-items: center;
		border: none;
		border-radius: 100%;
		cursor: pointer;
		display: flex;
		fab-fill: white;
		height: 12px;
		justify-content: center;
		position: absolute;
		// @startCleanup encore
		right: 12px;
		fab-background-color: gray6;
		top: 12px;
		// @endCleanup encore
		width: 12px;
		+encore() {
			right: 15px;
			top: 17px;
			background-color: var(--gray6);
		}
		// .FileCard__close:before,
		// .FileCard__close:after
		&:before,
		&:after {
			width: 1px;
			// @startCleanup encore
			fab-background-color: white;
			height: 8px;
			// @endCleanup encore
  		+encore() {
				background-color: var(--gray2);
				height: 6px;
			}
			content: '';
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
		}
		// .FileCard__close:before
		&:before {
			transform: translate3d(-50%, -50%, 0) rotate(45deg);
		}
		// .FileCard__close:after
		&:after {
			transform: translate3d(-50%, -50%, 0) rotate(-45deg);
		}
		// .FileCard__close:hover
		&:hover {
			// @startCleanup encore
			fab-background-color: gray7;
			// @endCleanup encore
			cursor: pointer;
			+encore() {
				background-color: var(--gray9);
				transition: background-color 200ms ease 0ms, fill 200ms ease 0ms;
			}
		}
	}
	// .FileCard__detail
	&__detail {
		flex: 1;
		max-width: calc(100% - 12px);
	}
	// .FileCard__info,
	// .FileCard__status
	&__info,
	&__status {
		// @startCleanup encore
		color: #80;
		font-size: 13px;
		// @endCleanup encore
		+encore() {
			color: var(--gray7);
			font-size: var(--font-size-small);
		}
	}
	// .FileCard__info
	&__info {
		align-items: center;
		display: flex;
		line-height: 1;
		// @startCleanup encore
		fab-color: gray7;
		fab-text: teenie;
		// @endCleanup encore
		height: auto;
		+encore() {
			color: var(--gray7);
			font-size: var(--font-size-small);
		}

		// .FileCard__info .FileCard__image
		.FileCard__image {
			margin: 0 6px 0 0;
		}
	}

	&__icon {
		line-height: 0;
		// @startCleanup encore
		margin-right: 6px;
		// @endCleanup encore
		+encore() {
			margin-right: 8px;
		}
	}
	// .FileCard__status
	&__status {
		display: inline-block;
		height: 17px;
		overflow: hidden;
	}
	// .FileCard__title
	&__title {
		display: inline-block;
		overflow: hidden;
		width: 100%;
		word-wrap: break-word;
		// @startCleanup encore
		height: 36px;
		font-size: inherit;
		line-height: inherit;
		// @endCleanup encore
		+encore() {
			height: auto;
			white-space: normal;
			font-size: var(--font-size-medium);
			font-weight: var(--font-weight-medium);
			line-height: var(--line-height-medium);
			max-height: var(--height--biggie);
			word-break: break-word;
		}
	}
	
	// .FileCard .bhrToggle
	.bhrToggle {
		// @startCleanup encore
		position: absolute;
		bottom: 15px; 
		right: 12px;
		// @endCleanup encore
		+encore() {
			position: absolute;
			bottom: 17px;
			right: 15px;
			width: 32px;
			height: 16px;
			user-select: none;
			cursor: pointer;
		}
	}

	.bhrToggle__label {
		+encore() {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: var(--gray4);
			transition: transform 0.2s;
			border-radius: 34px;
		}
	}

	/* Hide the default HTML checkbox */
	.bhrToggle__input {
		+encore() {
			opacity: 0;
			width: 0;
			height: 0;
		}
	}

	.bhrToggle__input:checked + .bhrToggle__label {
		+encore() {
			background-color: var(--fabric-theme-base);
		}
	}

	// .FileCard bhrToggle
	.bhrToggle__handle {
		+encore() {
			position: absolute;
			height: 12px;
			width: 12px;
			left: 2px;
			bottom: 2px;
			background-color: white;
			transition: transform 0.2s;
			border-radius: 50%;
		}
	}

	.bhrToggle__icon {
		// @startCleanup encore
		display: none;
		// @endCleanup encore
		+encore() {
			display: block;
			position: absolute;
			color: white;
			font-size: 16px;
			left: 20px;
			top: 50%;
			transform: translateY(-35%);
			transition: left 0.2s;
			pointer-events: none;
		}
	}

	.bhrToggle__input:checked + .bhrToggle__label + .bhrToggle__handle + .bhrToggle__icon {
		+encore() {
			left: 4px;
		}
	}

	.bhrToggle__input:checked + .bhrToggle__label + .bhrToggle__handle {
		+encore() {
			transform: translateX(16px);
		}
	}
}
